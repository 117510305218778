import React from 'react'
import Layout from '../components/layout'
import ImageTextBlock from '../components/imageTextBlock/imageTextBlockView'
import { graphql} from 'gatsby'





const PensionIndex = (props) => {

  const textBlocks = props.data.allContentfulPensionPage.nodes[0].content;


    return (
      <Layout title={props.data.allContentfulPensionPage.nodes[0].title} location={props.location}>
        <div className='empty_box'></div>
        <h1 style={{textAlign: 'center'}}>{props.data.allContentfulPensionPage.nodes[0].title}</h1>
        <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: 'var(--size-max-width)', justifyContent: 'space-between', margin: '0 auto' }}>
            {textBlocks.map((block) => <ImageTextBlock heading={block.heading} subHeading="" image={block.image?.gatsbyImageData} content={block.text} small={!block.largeBlock} />) }

        </div>

        


      </Layout>

    )
  }


export default PensionIndex

export const PageQuery = graphql`
query PensionQuery {
  allContentfulPensionPage {
    nodes {
      title
      content {
        image {
          gatsbyImageData
        }
        text {
          raw
        }
        largeBlock
        heading
      }
    }
  }
}
`



