import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import * as styles from './imageTextBlock.module.css'

const Introduction = ({ image, heading, subHeading ,content, small  }) => {
    let plaatje;
    if (image !== undefined){
        plaatje = <GatsbyImage alt="" className={styles.block_image} image={image}/>
    }
    else{
        plaatje = <div></div>
    }

    


    if(small){
        return(
            <div  className={styles.image_text_block_container_small}>
            {plaatje}
            <div className={styles.text_content}>
                <h2 className={styles.header}>{heading}</h2>
                <h3 className={styles.header}>{subHeading}</h3>
                {content && (
                    <div>{renderRichText(content)}</div>
                    )}
    
            </div>
        </div>
        )
    }

    else{
        return(
            <div  className={styles.image_text_block_container}>
            {plaatje}
            <div className={styles.text_content}>
                <h2 className={styles.header}>{heading}</h2>
                <h3 className={styles.header}>{subHeading}</h3>
                {content && (
                    <div>{renderRichText(content)}</div>
                    )}
    
            </div>
        </div>
        )
    }






}

export default Introduction